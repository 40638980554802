import React from 'react';

import { Trans, useTranslation } from "react-i18next";

import svg1 from '../../images/values1.svg'
import svg2 from '../../images/values2.svg'
import svg3 from '../../images/values3.svg'

function OurValues() {
  const { t } = useTranslation();
  return (
    <div className='bg-[#FBF6EC]'>
      <div className='max-w-[1280px] mx-auto'>
        <h1 className='text-[24px] xl:text-[40px] prosto-one text-center'>{t('ourvalues.1')}</h1>
        <div className='grid grid-cols-3 mt-[40px]'>
            <div className='items-center flex flex-col'>
                <img src={svg1} alt="" className='w-[45px] h-[45px] xl:w-[85px] xl:h-[85px]'/>
                <p className='text-center textcolor text-[14px] xl:text-[16px] mt-[30px] inter'>{t('ourvalues.2')}</p>
            </div>
            <div className='items-center flex flex-col'>
                <img src={svg2} alt="" className='w-[45px] h-[45px] xl:w-[85px] xl:h-[85px]'/>
                <p className='text-center textcolor text-[14px] xl:text-[16px] mt-[30px] inter'>{t('ourvalues.3')}</p>
            </div>
            <div className='items-center flex flex-col'>
                <img src={svg3} alt="" className='w-[45px] h-[45px] xl:w-[85px] xl:h-[85px]'/>
                <p className='text-center textcolor text-[14px] xl:text-[16px] mt-[30px] inter'>{t('ourvalues.4')}</p>
            </div>
        </div>
        <p className='text-center text-[16px] xl:text-[20px] text-[#1B6D76] mt-[60px] font-medium inter'><Trans>{t('ourvalues.5')}</Trans></p>
      </div>
    </div>
  );
}

export default OurValues;
import React from 'react';
import { useState, useEffect } from 'react';

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import i18n from "i18next";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

import logo from '../images/logo.svg'


function Header({scrollToRef}) {
    const languages = [
        { value: 'ua', label: 'UA' },
        { value: 'en', label: 'EN' },
        { value: 'ru', label: 'RU' },
      ]
    const { t } = useTranslation();
    const storedLang = Cookies.get("i18next");
    const [currentLang, setCurrentLang] = useState(storedLang || 'ua');

    useEffect(() => {
        if (storedLang && storedLang !== currentLang) {
        setCurrentLang(storedLang);
        i18n.changeLanguage(storedLang);
        }
    }, [storedLang]);

    const handleChangeLang = (selectedLang) => {
        setCurrentLang(selectedLang.value);
        i18n.changeLanguage(selectedLang.value);
        Cookies.set("i18next", selectedLang.value);
    };
    return (
        <div className='bg-[#FBF6EC]'>
            <div className='flex max-w-[1280px] xl:mx-auto pt-[5px] items-end gap-[40px] justify-between xl:justify-start mx-[20px]'>
                <img src={logo} alt="" />
                <Dropdown
                    options={languages}
                    placeholder="UA"
                    onChange={handleChangeLang}
                    value={languages.find(lang => lang.value === currentLang)}
                    className='mt-[20px] xl:mr-[30px] open-sans inter'
                />
                <button onClick={() => scrollToRef('advertisement')} className='xl:block hidden mb-[7px] textcolor cursor-pointer inter text-[16px]'>{t('header.1')}</button>
                <button onClick={() => scrollToRef('offers')} className='xl:block hidden mb-[7px] textcolor cursor-pointer inter text-[16px]'>{t('header.2')}</button>
                <button onClick={() => scrollToRef('about')} className='xl:block hidden mb-[7px] textcolor cursor-pointer inter text-[16px]'>{t('header.3')}</button>
                <button onClick={() => scrollToRef('ourvalues')} className='xl:block hidden mb-[7px] textcolor cursor-pointer inter text-[16px]'>{t('header.4')}</button>
            </div>
        </div>
    );
}

export default Header;
import React from 'react';

import { useTranslation } from "react-i18next";

import chairs from '../../images/chairs.png'
import chairs_mob from '../../images/chairs_mob.png'

function About() {
  const { t } = useTranslation();
  return (
    <div className='bg-[#FBF6EC]'>
      <div className='max-w-[1280px] xl:mx-auto bg-white xl:h-[632px] rounded-[20px] mx-[20px]'>
        <div className='flex flex-col xl:flex-row justify-between items-center'>
            <div className='mx-[20px] xl:mx-0 xl:ml-[50px] max-w-[618px]'>
                <h1 className='mt-[36px] xl:mt-0 text-[24px] xl:text-[40px] prosto-one'>{t('about.1')}</h1>
                <p className='text-[#1B6D76] inter text-[16px] xl:text-[20px] mt-[20px] xl:mt-[40px] font-medium'>{t('about.2')}</p>
                <p className='mt-[16px] xl:mt-[20px] textcolor text-[14px] xl:text-[16px] font-light'>{t('about.3')}</p>
                <p className='mt-[16px] xl:mt-[20px] textcolor text-[14px] xl:text-[16px] font-light'>{t('about.4')}</p>
                <p className='mt-[16px] xl:mt-[20px] textcolor text-[14px] xl:text-[16px] font-light'>{t('about.5')}</p>
            </div>
            <img src={chairs} alt="" className='hidden xl:block'/>
            <img src={chairs_mob} alt="" className='xl:hidden block'/>
        </div>
      </div>
    </div>
  );
}

export default About;
import React, { useState } from 'react';

import { useTranslation } from "react-i18next";

import main3 from '../../images/mainthree.png'

function Form() {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    phone: '',
    email: ''
  });

  const [submissionResult, setSubmissionResult] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if all fields are filled
    if (!formData.name || !formData.surname || !formData.phone || !formData.email) {
      setSubmissionResult(t('form.11'));
      return;
    }

    try {
      const response = await fetch('https://civbt.xyz/api/bot/CPxieF5J3O', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error('Failed to submit form data');
      }

      // Handle successful form submission
      setSubmissionResult(t('form.9'));
    } catch (error) {
      // Handle errors
      console.error('Error submitting form:', error);
      setSubmissionResult(t('form.10'));
    }
  };
  return (
    <div className='bg-[#FBF6EC]'>
        <div className='max-w-[1280px] xl:mx-auto bg-[#1B6D76] xl:h-[534px] mx-[20px] rounded-[20px]'>
            <img src={main3} alt="" className='absolute'/>
            <div className='flex flex-col relative z-10 items-center pt-[60px]'>
                <h1 className='prosto-one text-[#FBF6EC] text-[24px] xl:text-[40px] text-center mx-[20px] xl:mx-0'>{t('form.1')}</h1>
                <p className='text-[#FBF6EC] inter text-[16px] xl:text-[20px] pt-[20px] text-center mx-[20px] xl:mx-0'>{t('form.2')}</p>
                <p className='text-[#FBF6EC] inter text-[14px] xl:text-[16px] pt-[12px] text-center mx-[20px] xl:mx-0'>{t('form.3')}</p>
                <div className='flex flex-col xl:flex-row gap-[20px] pt-[60px] w-full xl:w-auto px-[20px] xl:px-0'>
                    <input placeholder={t('form.4')} type="text" name="name" value={formData.name} onChange={handleChange} className="text-[rgba(250,243,228,0.70)] appearance-none px-4 py-2 xl:w-[393px] h-[52px] shrink-0 rounded-full border-[1.75px] border-[rgba(250,243,228,0.70)] bg-[#1B6D76]"/>
                    <input placeholder={t('form.6')} type="text" name="phone" value={formData.phone} onChange={handleChange} className="text-[rgba(250,243,228,0.70)] appearance-none px-4 py-2 xl:w-[393px] h-[52px] shrink-0 rounded-full border-[1.75px] border-[rgba(250,243,228,0.70)] bg-[#1B6D76]"/>
                </div>
                <div className='flex flex-col xl:flex-row gap-[20px] mt-[20px] w-full xl:w-auto px-[20px] xl:px-0'>
                    <input placeholder={t('form.5')} type="text" name="surname" value={formData.surname} onChange={handleChange} className="text-[rgba(250,243,228,0.70)] appearance-none px-4 py-2 xl:w-[393px] h-[52px] shrink-0 rounded-full border-[1.75px] border-[rgba(250,243,228,0.70)] bg-[#1B6D76]"/>
                    <input placeholder={t('form.7')} type="text" name="email" value={formData.email} onChange={handleChange} className="text-[rgba(250,243,228,0.70)] appearance-none px-4 py-2 xl:w-[393px] h-[52px] shrink-0 rounded-full border-[1.75px] border-[rgba(250,243,228,0.70)] bg-[#1B6D76]"/>
                </div>
                <div onClick={handleSubmit} className='bg-white button w-max mt-[40px] cursor-pointer'>
                    <p className='text-[#1B6D76] prosto-one text-[14px] xl:text-[16px]'>{t('form.8')}</p>
                </div>
                {submissionResult && (
                    <p className='text-green-500 pt-[10px]'>{submissionResult}</p>
                )}
                <div className='mt-[20px] xl:mt-0'></div>
            </div>
        </div>
    </div>
  );
}

export default Form;
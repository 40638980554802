import React from 'react';

import { useTranslation } from "react-i18next";

function Offers() {
  const { t } = useTranslation();
  return (
    <div className='bg-[#FBF6EC]'>
      <div className='max-w-[1280px] mx-auto'>
        <div className='flex flex-col xl:flex-row justify-between mx-[20px] xl:mx-0'>
            <div>
                <h1 className='text-[24px] xl:text-[40px] prosto-one'>{t('offers.1')}</h1>
                <div className='flex items-start mt-[40px]'>
                    <svg className="mt-[2px]" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="20" height="20" rx="10" fill="#1B6D76"/>
                        <path d="M15.0384 7.23892C15.207 7.39832 15.3054 7.61815 15.312 7.85008C15.3185 8.08201 15.2327 8.30705 15.0734 8.47573L10.1145 13.7263C10.0341 13.8113 9.93753 13.8793 9.83045 13.9262C9.72336 13.9732 9.60795 13.9983 9.49102 13.9999C9.37409 14.0015 9.25802 13.9797 9.14967 13.9357C9.04132 13.8918 8.94288 13.8265 8.86017 13.7438L6.23487 11.1185C6.08029 10.9526 5.99614 10.7332 6.00014 10.5065C6.00414 10.2798 6.09598 10.0635 6.25631 9.90317C6.41664 9.74283 6.63295 9.65099 6.85966 9.64699C7.08637 9.64299 7.30579 9.72715 7.47168 9.88172L9.46107 11.87L13.8016 7.27392C13.961 7.10532 14.1808 7.00691 14.4127 7.00035C14.6447 6.99379 14.8697 7.0796 15.0384 7.23892Z" fill="white"/>
                    </svg>
                    <p className='textcolor inter text-[14px] xl:text-[16px] ml-[20px]'>{t('offers.2')}</p> 
                </div>
                <div className='flex items-start mt-[20px]'>
                    <svg className="mt-[2px]" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="20" height="20" rx="10" fill="#1B6D76"/>
                        <path d="M15.0384 7.23892C15.207 7.39832 15.3054 7.61815 15.312 7.85008C15.3185 8.08201 15.2327 8.30705 15.0734 8.47573L10.1145 13.7263C10.0341 13.8113 9.93753 13.8793 9.83045 13.9262C9.72336 13.9732 9.60795 13.9983 9.49102 13.9999C9.37409 14.0015 9.25802 13.9797 9.14967 13.9357C9.04132 13.8918 8.94288 13.8265 8.86017 13.7438L6.23487 11.1185C6.08029 10.9526 5.99614 10.7332 6.00014 10.5065C6.00414 10.2798 6.09598 10.0635 6.25631 9.90317C6.41664 9.74283 6.63295 9.65099 6.85966 9.64699C7.08637 9.64299 7.30579 9.72715 7.47168 9.88172L9.46107 11.87L13.8016 7.27392C13.961 7.10532 14.1808 7.00691 14.4127 7.00035C14.6447 6.99379 14.8697 7.0796 15.0384 7.23892Z" fill="white"/>
                    </svg>
                    <p className='textcolor inter text-[14px] xl:text-[16px] ml-[20px]'>{t('offers.3')}</p> 
                </div>
                <div className='flex items-start mt-[20px]'>
                    <svg className="mt-[2px]" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="20" height="20" rx="10" fill="#1B6D76"/>
                        <path d="M15.0384 7.23892C15.207 7.39832 15.3054 7.61815 15.312 7.85008C15.3185 8.08201 15.2327 8.30705 15.0734 8.47573L10.1145 13.7263C10.0341 13.8113 9.93753 13.8793 9.83045 13.9262C9.72336 13.9732 9.60795 13.9983 9.49102 13.9999C9.37409 14.0015 9.25802 13.9797 9.14967 13.9357C9.04132 13.8918 8.94288 13.8265 8.86017 13.7438L6.23487 11.1185C6.08029 10.9526 5.99614 10.7332 6.00014 10.5065C6.00414 10.2798 6.09598 10.0635 6.25631 9.90317C6.41664 9.74283 6.63295 9.65099 6.85966 9.64699C7.08637 9.64299 7.30579 9.72715 7.47168 9.88172L9.46107 11.87L13.8016 7.27392C13.961 7.10532 14.1808 7.00691 14.4127 7.00035C14.6447 6.99379 14.8697 7.0796 15.0384 7.23892Z" fill="white"/>
                    </svg>
                    <p className='textcolor inter text-[14px] xl:text-[16px] ml-[20px]'>{t('offers.4')}</p> 
                </div>
                <div className='flex items-start mt-[20px]'>
                    <svg className="mt-[2px]" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="20" height="20" rx="10" fill="#1B6D76"/>
                        <path d="M15.0384 7.23892C15.207 7.39832 15.3054 7.61815 15.312 7.85008C15.3185 8.08201 15.2327 8.30705 15.0734 8.47573L10.1145 13.7263C10.0341 13.8113 9.93753 13.8793 9.83045 13.9262C9.72336 13.9732 9.60795 13.9983 9.49102 13.9999C9.37409 14.0015 9.25802 13.9797 9.14967 13.9357C9.04132 13.8918 8.94288 13.8265 8.86017 13.7438L6.23487 11.1185C6.08029 10.9526 5.99614 10.7332 6.00014 10.5065C6.00414 10.2798 6.09598 10.0635 6.25631 9.90317C6.41664 9.74283 6.63295 9.65099 6.85966 9.64699C7.08637 9.64299 7.30579 9.72715 7.47168 9.88172L9.46107 11.87L13.8016 7.27392C13.961 7.10532 14.1808 7.00691 14.4127 7.00035C14.6447 6.99379 14.8697 7.0796 15.0384 7.23892Z" fill="white"/>
                    </svg>
                    <p className='textcolor inter text-[14px] xl:text-[16px] ml-[20px]'>{t('offers.5')}</p> 
                </div>
                <div className='flex items-start mt-[20px]'>
                    <svg className="mt-[2px]" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="20" height="20" rx="10" fill="#1B6D76"/>
                        <path d="M15.0384 7.23892C15.207 7.39832 15.3054 7.61815 15.312 7.85008C15.3185 8.08201 15.2327 8.30705 15.0734 8.47573L10.1145 13.7263C10.0341 13.8113 9.93753 13.8793 9.83045 13.9262C9.72336 13.9732 9.60795 13.9983 9.49102 13.9999C9.37409 14.0015 9.25802 13.9797 9.14967 13.9357C9.04132 13.8918 8.94288 13.8265 8.86017 13.7438L6.23487 11.1185C6.08029 10.9526 5.99614 10.7332 6.00014 10.5065C6.00414 10.2798 6.09598 10.0635 6.25631 9.90317C6.41664 9.74283 6.63295 9.65099 6.85966 9.64699C7.08637 9.64299 7.30579 9.72715 7.47168 9.88172L9.46107 11.87L13.8016 7.27392C13.961 7.10532 14.1808 7.00691 14.4127 7.00035C14.6447 6.99379 14.8697 7.0796 15.0384 7.23892Z" fill="white"/>
                    </svg>
                    <p className='textcolor inter text-[14px] xl:text-[16px] ml-[20px]'>{t('offers.6')}</p> 
                </div>
                <div className='flex items-start mt-[20px]'>
                    <svg className="mt-[2px]" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="20" height="20" rx="10" fill="#1B6D76"/>
                        <path d="M15.0384 7.23892C15.207 7.39832 15.3054 7.61815 15.312 7.85008C15.3185 8.08201 15.2327 8.30705 15.0734 8.47573L10.1145 13.7263C10.0341 13.8113 9.93753 13.8793 9.83045 13.9262C9.72336 13.9732 9.60795 13.9983 9.49102 13.9999C9.37409 14.0015 9.25802 13.9797 9.14967 13.9357C9.04132 13.8918 8.94288 13.8265 8.86017 13.7438L6.23487 11.1185C6.08029 10.9526 5.99614 10.7332 6.00014 10.5065C6.00414 10.2798 6.09598 10.0635 6.25631 9.90317C6.41664 9.74283 6.63295 9.65099 6.85966 9.64699C7.08637 9.64299 7.30579 9.72715 7.47168 9.88172L9.46107 11.87L13.8016 7.27392C13.961 7.10532 14.1808 7.00691 14.4127 7.00035C14.6447 6.99379 14.8697 7.0796 15.0384 7.23892Z" fill="white"/>
                    </svg>
                    <p className='textcolor inter text-[14px] xl:text-[16px] ml-[20px]'>{t('offers.7')}</p> 
                </div>
            </div>
            <div className='xl:mt-0 mt-[80px]'>
                <h1 className='text-[24px] xl:text-[40px] prosto-one'>{t('offers.8')}</h1>
                <div className='flex items-start mt-[40px]'>
                    <svg className="mt-[2px]" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="20" height="20" rx="10" fill="#1B6D76"/>
                        <path d="M15.0384 7.23892C15.207 7.39832 15.3054 7.61815 15.312 7.85008C15.3185 8.08201 15.2327 8.30705 15.0734 8.47573L10.1145 13.7263C10.0341 13.8113 9.93753 13.8793 9.83045 13.9262C9.72336 13.9732 9.60795 13.9983 9.49102 13.9999C9.37409 14.0015 9.25802 13.9797 9.14967 13.9357C9.04132 13.8918 8.94288 13.8265 8.86017 13.7438L6.23487 11.1185C6.08029 10.9526 5.99614 10.7332 6.00014 10.5065C6.00414 10.2798 6.09598 10.0635 6.25631 9.90317C6.41664 9.74283 6.63295 9.65099 6.85966 9.64699C7.08637 9.64299 7.30579 9.72715 7.47168 9.88172L9.46107 11.87L13.8016 7.27392C13.961 7.10532 14.1808 7.00691 14.4127 7.00035C14.6447 6.99379 14.8697 7.0796 15.0384 7.23892Z" fill="white"/>
                    </svg>
                    <p className='textcolor inter text-[14px] xl:text-[16px] ml-[20px]'>{t('offers.9')}</p> 
                </div>
                <div className='flex items-start mt-[20px]'>
                    <svg className="mt-[2px]" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="20" height="20" rx="10" fill="#1B6D76"/>
                        <path d="M15.0384 7.23892C15.207 7.39832 15.3054 7.61815 15.312 7.85008C15.3185 8.08201 15.2327 8.30705 15.0734 8.47573L10.1145 13.7263C10.0341 13.8113 9.93753 13.8793 9.83045 13.9262C9.72336 13.9732 9.60795 13.9983 9.49102 13.9999C9.37409 14.0015 9.25802 13.9797 9.14967 13.9357C9.04132 13.8918 8.94288 13.8265 8.86017 13.7438L6.23487 11.1185C6.08029 10.9526 5.99614 10.7332 6.00014 10.5065C6.00414 10.2798 6.09598 10.0635 6.25631 9.90317C6.41664 9.74283 6.63295 9.65099 6.85966 9.64699C7.08637 9.64299 7.30579 9.72715 7.47168 9.88172L9.46107 11.87L13.8016 7.27392C13.961 7.10532 14.1808 7.00691 14.4127 7.00035C14.6447 6.99379 14.8697 7.0796 15.0384 7.23892Z" fill="white"/>
                    </svg>
                    <p className='textcolor inter text-[14px] xl:text-[16px] ml-[20px]'>{t('offers.10')}</p> 
                </div>
                <div className='flex items-start mt-[20px]'>
                    <svg className="mt-[2px]" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="20" height="20" rx="10" fill="#1B6D76"/>
                        <path d="M15.0384 7.23892C15.207 7.39832 15.3054 7.61815 15.312 7.85008C15.3185 8.08201 15.2327 8.30705 15.0734 8.47573L10.1145 13.7263C10.0341 13.8113 9.93753 13.8793 9.83045 13.9262C9.72336 13.9732 9.60795 13.9983 9.49102 13.9999C9.37409 14.0015 9.25802 13.9797 9.14967 13.9357C9.04132 13.8918 8.94288 13.8265 8.86017 13.7438L6.23487 11.1185C6.08029 10.9526 5.99614 10.7332 6.00014 10.5065C6.00414 10.2798 6.09598 10.0635 6.25631 9.90317C6.41664 9.74283 6.63295 9.65099 6.85966 9.64699C7.08637 9.64299 7.30579 9.72715 7.47168 9.88172L9.46107 11.87L13.8016 7.27392C13.961 7.10532 14.1808 7.00691 14.4127 7.00035C14.6447 6.99379 14.8697 7.0796 15.0384 7.23892Z" fill="white"/>
                    </svg>
                    <p className='textcolor inter text-[14px] xl:text-[16px] ml-[20px]'>{t('offers.11')}</p> 
                </div>
                <div className='flex items-start mt-[20px]'>
                    <svg className="mt-[2px]" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="20" height="20" rx="10" fill="#1B6D76"/>
                        <path d="M15.0384 7.23892C15.207 7.39832 15.3054 7.61815 15.312 7.85008C15.3185 8.08201 15.2327 8.30705 15.0734 8.47573L10.1145 13.7263C10.0341 13.8113 9.93753 13.8793 9.83045 13.9262C9.72336 13.9732 9.60795 13.9983 9.49102 13.9999C9.37409 14.0015 9.25802 13.9797 9.14967 13.9357C9.04132 13.8918 8.94288 13.8265 8.86017 13.7438L6.23487 11.1185C6.08029 10.9526 5.99614 10.7332 6.00014 10.5065C6.00414 10.2798 6.09598 10.0635 6.25631 9.90317C6.41664 9.74283 6.63295 9.65099 6.85966 9.64699C7.08637 9.64299 7.30579 9.72715 7.47168 9.88172L9.46107 11.87L13.8016 7.27392C13.961 7.10532 14.1808 7.00691 14.4127 7.00035C14.6447 6.99379 14.8697 7.0796 15.0384 7.23892Z" fill="white"/>
                    </svg>
                    <p className='textcolor inter text-[14px] xl:text-[16px] ml-[20px]'>{t('offers.12')}</p> 
                </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Offers;
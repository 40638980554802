import React, { useState, useEffect } from 'react';
import './Modal.css';
import ReactDOM from 'react-dom';

import { useTranslation } from "react-i18next";

import maintwo from "../../images/maintwo.png"

import man from "../../images/man.png"
import girl from "../../images/girl.png"

const Modal = ({ isOpen, onClose, goToModal }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <>
      <div className="backdrop" onClick={onClose}></div>
      <div className="modal">
        <div className="bg-white mx-[20px] w-screen px-[20px] xl:w-[1220px] h-[413px] rounded-[20px] flex flex-col xl:flex-row overflow-y-scroll xl:overflow-y-auto justify-between relative">
            <div className='absolute right-0 cursor-pointer' onClick={onClose}>
              <svg className='mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68" fill="none">
                <path d="M43.8831 24.0845L24.0586 43.858M24.0417 24.0421L43.8407 43.8411" stroke="#17656D" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div className='max-w-[505px]'>
              <h1 className='inter text-[14px] xl:text-[16px] text-[#000000B2] font-[600] xl:mx-[40px] mt-[40px]'>{t('add.advertisement.1')}</h1>
              <div className='flex items-center xl:ml-[40px] mt-[16px]'>
                <svg className='mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <circle cx="3" cy="3" r="3" fill="#D9D9D9"/>
                </svg>
                <p className='max-w-[450px] ml-[6px] font-[300] inter text-[14px] xl:text-[16px] text-[#000000B2]'>{t('add.advertisement.2')}</p>
              </div>
              <div className='flex items-center xl:ml-[40px] mt-[8px]'>
                <svg className='mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <circle cx="3" cy="3" r="3" fill="#D9D9D9"/>
                </svg>
                <p className='max-w-[450px] ml-[6px] font-[300] inter text-[14px] xl:text-[16px] text-[#000000B2]'>{t('add.advertisement.3')}</p>
              </div>
              <div className='flex items-center xl:ml-[40px] mt-[8px]'>
                <svg className='mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <circle cx="3" cy="3" r="3" fill="#D9D9D9"/>
                </svg>
                <p className='max-w-[450px] ml-[6px] font-[300] inter text-[14px] xl:text-[16px] text-[#000000B2]'>{t('add.advertisement.4')}</p>
              </div>
              <div className='flex items-center xl:ml-[40px] mt-[8px]'>
                <svg className='mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <circle cx="3" cy="3" r="3" fill="#D9D9D9"/>
                </svg>
                <p className='max-w-[450px] ml-[6px] font-[300] inter text-[14px] xl:text-[16px] text-[#000000B2]'>{t('add.advertisement.5')}</p>
              </div>
              <div className='bg-[#1B6D76] button w-max mt-[50px] cursor-pointer xl:mx-[40px]' onClick={goToModal}>
                <p className='text-white prosto-one text-[14px] xl:text-[16px]'>{t('advertisement.11')}</p>
              </div>
            </div>
            <div className='max-w-[505px] mr-[80px]'>
              <h1 className='inter text-[14px] xl:text-[16px] text-[#000000B2] font-[600] mt-[40px]'>{t('add.advertisement.6')}</h1>
              <div className='flex items-center mt-[16px]'>
                <svg className='mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <circle cx="3" cy="3" r="3" fill="#D9D9D9"/>
                </svg>
                <p className='max-w-[450px] ml-[6px] font-[300] inter text-[14px] xl:text-[16px] text-[#000000B2]'>{t('add.advertisement.7')}</p>
              </div>
              <div className='flex items-center mt-[8px]'>
                <svg className='mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <circle cx="3" cy="3" r="3" fill="#D9D9D9"/>
                </svg>
                <p className='max-w-[450px] ml-[6px] font-[300] inter text-[14px] xl:text-[16px] text-[#000000B2]'>{t('add.advertisement.8')}</p>
              </div>
              <div className='flex items-center mt-[8px]'>
                <svg className='mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <circle cx="3" cy="3" r="3" fill="#D9D9D9"/>
                </svg>
                <p className='max-w-[450px] ml-[6px] font-[300] inter text-[14px] xl:text-[16px] text-[#000000B2]'>{t('add.advertisement.9')}</p>
              </div>
              <div className='flex items-start mt-[8px]'>
                <svg className='mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <circle cx="3" cy="3" r="3" fill="#D9D9D9"/>
                </svg>
                <p className='max-w-[450px] ml-[6px] font-[300] inter text-[14px] xl:text-[16px] text-[#000000B2]'>{t('add.advertisement.10')}</p>
              </div>
            </div>
          </div>
      </div>
    </>,
    document.body
  );
};

const Modal2 = ({ isOpen, onClose, goToModal }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <>
      <div className="backdrop" onClick={onClose}></div>
      <div className="modal">
        <div className="bg-white mx-[20px] w-screen px-[20px] xl:w-[1220px] h-[413px] rounded-[20px] flex flex-col xl:flex-row overflow-y-scroll xl:overflow-y-auto justify-between relative">
            <div className='absolute right-0 cursor-pointer' onClick={onClose}>
              <svg className='mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68" fill="none">
                <path d="M43.8831 24.0845L24.0586 43.858M24.0417 24.0421L43.8407 43.8411" stroke="#17656D" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div className='max-w-[505px]'>
              <h1 className='inter text-[14px] xl:text-[16px] text-[#000000B2] font-[600] xl:mx-[40px] mt-[40px]'>{t('add.advertisement.11')}</h1>
              <div className='flex items-start xl:ml-[40px] mt-[16px]'>
                <svg className='mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <circle cx="3" cy="3" r="3" fill="#D9D9D9"/>
                </svg>
                <p className='max-w-[450px] ml-[6px] font-[300] inter text-[14px] xl:text-[16px] text-[#000000B2]'>{t('add.advertisement.12')}</p>
              </div>
              <div className='flex items-start xl:ml-[40px] mt-[8px]'>
                <svg className='mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <circle cx="3" cy="3" r="3" fill="#D9D9D9"/>
                </svg>
                <p className='max-w-[450px] ml-[6px] font-[300] inter text-[14px] xl:text-[16px] text-[#000000B2]'>{t('add.advertisement.13')}</p>
              </div>
              <div className='flex items-start xl:ml-[40px] mt-[8px]'>
                <svg className='mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <circle cx="3" cy="3" r="3" fill="#D9D9D9"/>
                </svg>
                <p className='max-w-[450px] ml-[6px] font-[300] inter text-[14px] xl:text-[16px] text-[#000000B2]'>{t('add.advertisement.14')}</p>
              </div>
              <div className='flex items-start xl:ml-[40px] mt-[8px]'>
                <svg className='mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <circle cx="3" cy="3" r="3" fill="#D9D9D9"/>
                </svg>
                <p className='max-w-[450px] ml-[6px] font-[300] inter text-[14px] xl:text-[16px] text-[#000000B2]'>{t('add.advertisement.15')}</p>
              </div>
              <h1 className='inter text-[14px] xl:text-[16px] text-[#000000B2] font-[600] xl:mx-[40px] mt-[40px]'>{t('add.advertisement.16')}</h1>
              <div className='flex items-start xl:ml-[40px] mt-[16px]'>
                <svg className='mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <circle cx="3" cy="3" r="3" fill="#D9D9D9"/>
                </svg>
                <p className='max-w-[450px] ml-[6px] font-[300] inter text-[14px] xl:text-[16px] text-[#000000B2]'>{t('add.advertisement.17')}</p>
              </div>
              <div className='flex items-start xl:ml-[40px] mt-[8px]'>
                <svg className='mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <circle cx="3" cy="3" r="3" fill="#D9D9D9"/>
                </svg>
                <p className='max-w-[450px] ml-[6px] font-[300] inter text-[14px] xl:text-[16px] text-[#000000B2]'>{t('add.advertisement.18')}</p>
              </div>
              <div className='bg-[#1B6D76] button w-max mt-[50px] cursor-pointer xl:mx-[40px]' onClick={goToModal}>
                <p className='text-white prosto-one text-[14px] xl:text-[16px]'>{t('advertisement.11')}</p>
              </div>
            </div>
            <div className='max-w-[505px] mr-[80px]'>
              <div className='flex items-start mt-[8px]'>
                <svg className='mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <circle cx="3" cy="3" r="3" fill="#D9D9D9"/>
                </svg>
                <p className='max-w-[450px] ml-[6px] font-[300] inter text-[14px] xl:text-[16px] text-[#000000B2]'>{t('add.advertisement.19')}</p>
              </div>
              <div className='flex items-start mt-[8px]'>
                <svg className='mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <circle cx="3" cy="3" r="3" fill="#D9D9D9"/>
                </svg>
                <p className='max-w-[450px] ml-[6px] font-[300] inter text-[14px] xl:text-[16px] text-[#000000B2]'>{t('add.advertisement.20')}</p>
              </div>
              <h1 className='inter text-[14px] xl:text-[16px] text-[#000000B2] font-[600] mt-[40px]'>{t('add.advertisement.21')}</h1>
              <div className='flex items-start mt-[16px]'>
                <svg className='mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <circle cx="3" cy="3" r="3" fill="#D9D9D9"/>
                </svg>
                <p className='max-w-[450px] ml-[6px] font-[300] inter text-[14px] xl:text-[16px] text-[#000000B2]'>{t('add.advertisement.22')}</p>
              </div>
              <div className='flex items-start mt-[8px]'>
                <svg className='mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <circle cx="3" cy="3" r="3" fill="#D9D9D9"/>
                </svg>
                <p className='max-w-[450px] ml-[6px] font-[300] inter text-[14px] xl:text-[16px] text-[#000000B2]'>{t('add.advertisement.23')}</p>
              </div>
              <div className='flex items-start mt-[8px]'>
                <svg className='mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <circle cx="3" cy="3" r="3" fill="#D9D9D9"/>
                </svg>
                <p className='max-w-[450px] ml-[6px] font-[300] inter text-[14px] xl:text-[16px] text-[#000000B2]'>{t('add.advertisement.24')}</p>
              </div>
              <div className='flex items-start mt-[8px]'>
                <svg className='mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <circle cx="3" cy="3" r="3" fill="#D9D9D9"/>
                </svg>
                <p className='max-w-[450px] ml-[6px] font-[300] inter text-[14px] xl:text-[16px] text-[#000000B2]'>{t('add.advertisement.25')}</p>
              </div>
              <h1 className='inter text-[14px] xl:text-[16px] text-[#000000B2] font-[600] mt-[40px]'>{t('add.advertisement.26')}</h1>
              <div className='flex items-start mt-[16px]'>
                <svg className='mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <circle cx="3" cy="3" r="3" fill="#D9D9D9"/>
                </svg>
                <p className='max-w-[450px] ml-[6px] font-[300] inter text-[14px] xl:text-[16px] text-[#000000B2]'>{t('add.advertisement.27')}</p>
              </div>
              <div className='flex items-start mt-[8px]'>
                <svg className='mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <circle cx="3" cy="3" r="3" fill="#D9D9D9"/>
                </svg>
                <p className='max-w-[450px] ml-[6px] font-[300] inter text-[14px] xl:text-[16px] text-[#000000B2]'>{t('add.advertisement.28')}</p>
              </div>
              <div className='flex items-start mt-[8px]'>
                <svg className='mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <circle cx="3" cy="3" r="3" fill="#D9D9D9"/>
                </svg>
                <p className='max-w-[450px] ml-[6px] font-[300] inter text-[14px] xl:text-[16px] text-[#000000B2]'>{t('add.advertisement.29')}</p>
              </div>
              <div className='flex items-start mt-[8px]'>
                <svg className='mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <circle cx="3" cy="3" r="3" fill="#D9D9D9"/>
                </svg>
                <p className='max-w-[450px] ml-[6px] font-[300] inter text-[14px] xl:text-[16px] text-[#000000B2]'>{t('add.advertisement.30')}</p>
              </div>
              <div className='flex items-start mt-[8px]'>
                <svg className='mt-[10px]' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                  <circle cx="3" cy="3" r="3" fill="#D9D9D9"/>
                </svg>
                <p className='max-w-[450px] ml-[6px] font-[300] inter text-[14px] xl:text-[16px] text-[#000000B2]'>{t('add.advertisement.31')}</p>
              </div>
            </div>
          </div>
      </div>
    </>,
    document.body
  );
};

function Advertisement({scrollToRef}) {

  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);

  const openModal = () => {
    setModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const openModal2 = () => {
    setModalOpen2(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalOpen2(false);
    document.body.style.overflow = '';
  };

  const goToModal = () => {
    setModalOpen(false);
    setModalOpen2(false);
    scrollToRef('form');
    document.body.style.overflow = '';
  };

  return (
    <div className='bg-[#FBF6EC]'>
      <Modal isOpen={modalOpen} onClose={closeModal} goToModal={goToModal} />

      <Modal2 isOpen={modalOpen2} onClose={closeModal} goToModal={goToModal} />

      <div className='max-w-[1280px] mx-auto'>
        <h1 className='text-[24px] xl:text-[40px] prosto-one mx-[20px] xl:mx-0'>{t('advertisement.1')}</h1>
        <div className='bg-white rounded-[20px] w-auto xl:h-[487px] mt-[40px] flex flex-col xl:flex-row justify-between items-center mx-[20px] xl:mx-0'>
          <div className='xl:pl-[40px] mt-[36px] xl:mt-0 mx-[20px] xl:mx-0'>
            <h1 className='prosto-one text-[16px] xl:text-[24px]'>{t('advertisement.2')}</h1>
            <p className='max-w-[504px] textcolor inter text-[14px] xl:text-[16px] mt-[40px]'>{t('advertisement.4')}</p>
            <p className='max-w-[504px] inter text-[14px] xl:text-[16px] mt-[40px] font-medium'>{t('advertisement.5')}</p>
            <div className='bg-[#1B6D76] button w-max mt-[60px] cursor-pointer' onClick={openModal}>
                <p className='text-white prosto-one text-[14px] xl:text-[16px]'>{t('advertisement.6')}</p>
            </div>
          </div>
          <div className='bg-[#FBF6EC] xl:w-[507px] xl:h-[467px] h-[300px] rounded-[20px] my-[10px] mx-[10px] mt-[50px] xl:mt-[10px] flex justify-center'>
            <img src={maintwo} alt="" className='xl:block absolute hidden'/>
            <img src={man} alt="" className='relative z-10 object-cover'/>
          </div>
        </div>
        <div className='bg-white rounded-[20px] w-auto xl:h-[487px] mt-[40px] flex flex-col-reverse xl:flex-row justify-between items-center mx-[20px] xl:mx-0'>
          <div className='bg-[#FBF6EC] xl:w-[507px] xl:h-[467px] h-[300px] rounded-[20px] my-[10px] mx-[10px] mt-[50px] xl:mt-[10px] flex justify-center'>
            <img src={maintwo} alt="" className='xl:block absolute hidden'/>
            <img src={girl} alt="" className='relative z-10 object-cover'/>
          </div>
          <div className='xl:pr-[40px] mt-[36px] xl:mt-0 mx-[20px] xl:mx-0'>
            <h1 className='prosto-one text-[16px] xl:text-[24px]'>{t('advertisement.7')}</h1>
            <p className='max-w-[504px] textcolor inter text-[14px] xl:text-[16px] mt-[40px]'>{t('advertisement.9')}</p>
            <p className='max-w-[504px] inter text-[14px] xl:text-[16px] mt-[40px] font-medium'>{t('advertisement.10')}</p>
            <div className='bg-[#1B6D76] button w-max mt-[60px] cursor-pointer' onClick={openModal2}>
                <p className='text-white prosto-one text-[14px] xl:text-[16px]'>{t('advertisement.11')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Advertisement;
import React from 'react';

import mainbg from '../../images/main.png'
import people from '../../images/people.png'
import peoplephone from '../../images/peoplephone.png'

import { useTranslation } from "react-i18next";

function Main({scrollToRef}) {
  const { t } = useTranslation();
  return (
    <div className='bg-[#FBF6EC]'>
        <div className='max-w-[1280px] mx-auto'>
            <div className='bg-[#1B6D76] h-[581px] xl:h-[534px] w-auto rounded-[20px] mx-[20px] xl:mx-0 overflow-hidden'>
                <img src={mainbg} alt="" className='absolute w-[300px] xl:w-auto'/>
                <div className='flex flex-col xl:flex-row xl:justify-between overflow-hidden'>
                    <div className='flex flex-col justify-center z-10 max-w-[485px] xl:ml-[60px] ml-[20px]'>
                        <h1 className='mt-[40px] xl:mt-0 prosto-one text-white uppercase text-[32px] xl:text-[48px]'>Smart Group</h1>
                        <p className='inter text-[16px] xl:text-[20px] text-white mt-[10px] font-light'>{t('main.1')}</p>
                        <div className='bg-white button w-max mt-[30px] xl:mt-[70px] cursor-pointer' onClick={() => scrollToRef('form')}>
                            <p className='text-[#1B6D76] prosto-one text-[14px] xl:text-[16px]'>{t('main.2')}</p>
                        </div>
                    </div>
                    <img src={people} alt="" className='relative z-10 hidden md:block'/>
                    <img src={peoplephone} alt="" className='mt-[20px] relative z-10 md:hidden block'/>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Main;
import React from 'react';

import Fade from 'react-reveal/Fade';

import Header from '../components/Header';

import Main from '../components/Home/Main';
import Advertisement from '../components/Home/Advertisement';
import Offers from '../components/Home/Offers';
import About from '../components/Home/About';
import OurValues from '../components/Home/OurValues';
import Form from '../components/Home/Form';

function Home() {
  const scrollToRef = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div className='bg-[#FBF6EC] overflow-x-hidden'>
      <Header scrollToRef={scrollToRef} />
      <div className='pt-[20px]'/>
        <Fade bottom><Main scrollToRef={scrollToRef}/></Fade>
      <div className='pt-[80px] xl:pt-[160px]'/>
      <div id="advertisement">
        <Fade bottom><Advertisement scrollToRef={scrollToRef}/></Fade>
      </div>
      <div className='pt-[80px] xl:pt-[160px]'/>
      <div id="offers">
        <Fade bottom><Offers /></Fade>
      </div>
      <div className='pt-[80px] xl:pt-[160px]'/>
      <div id="about">
        <Fade bottom><About /></Fade>
      </div>
      <div className='pt-[80px] xl:pt-[160px]'/>
      <div id="ourvalues">
        <Fade bottom><OurValues/></Fade>
      </div>
      <div className='pt-[80px] xl:pt-[160px]'/>
      <div id="form">
        <Fade bottom><Form/></Fade>
      </div>
      <div className='pt-[140px] xl:pt-[160px]'/>
    </div>
  );
}

export default Home;